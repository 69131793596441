import en from '~/lang/en.json'
import ru from '~/lang/ru.json'

export default defineNuxtPlugin(() => ({
  legacy: false,
  locales: ['en', 'ru'],
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: {
    en, ru
  }
}))
